import React, { Component } from 'react';

class Breadcrumb extends Component {
    app = null;

    constructor(props) {
        super();

        var steps = [{step: 0, name: "Felhasználó", url: ""},{step: 1, name: "Művelet", url:"actions"}];
        if(props.steps !== undefined) steps = props.steps;
        
        this.state = {steps: steps, step: steps[0], maxStep: 0};
        
        if(props.app !== undefined) {
            this.app = props.app;
            this.app.breadCrumb = this;
        }
    }
        
    render() {
        return (
        <div className="Breadcrumb-content">
            {this.renderSteps()}
        </div>
        );
    }

    breadcrumbButtonHandler(step) {
        if(this.state.maxStep<step.step) return; //not available yet
        this.setStep(step);
    }

    setStep(step) {
        var maxStep = this.state.maxStep;
        if(this.state.maxStep<step.step) maxStep = step.step;
        this.setState({step: step, maxStep: maxStep});
        this.app.setState({step: step});
    }

    renderSteps() {
        var toDisplay = this.state.steps.map(function(step) {
            var nextSign = (<span>&nbsp;»&nbsp;</span>);
            if(step.step===0) nextSign = "";
            return (
               <div className="Breadcrumb-step" key={"bcstep" + step.step + ""}>{nextSign}<button className={((step === this.state.step)?"active":"")} onClick={() => this.breadcrumbButtonHandler(step)}>{step.name}</button></div>
            );
        }.bind(this));

        return toDisplay;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.maxStep !== nextState.maxStep) return true;
            if(this.state.step.step !== nextState.step.step) return true;
        return false;
    }
}

export default Breadcrumb;
