import React, { Component } from 'react';
import Dataconnection from './../tools/dataconnection';
import Barcode from 'react-barcode';
import DetailSelector from './detailSelector';

class Details extends Component {
    app = null;

    constructor(props) {
        super();

        var loaded = false;
        if(props.app!==undefined) this.app = props.app;
        if(props.loaded!==undefined) loaded = props.loaded;
                
        if(this.app.state.orderDetails === null) {
            this.app.state.orderDetails = [];
        }

        this.state = {loaded: loaded, is_error: false, reloadData: false, error: ""};
    }

    reloadData() {
        if(this.state.reloadData !== true && this.state.loaded) return;

        //Get data
        if(this.app.state.order.id === undefined && this.app.state.action.id==="add") {
            this.app.setState({orderDetails: []});
            this.setState({loaded: true, is_error: false, reloadData: false});
            return;
        }
        var params = "/" + this.app.state.order.id;
        fetch(Dataconnection.getUrl("orders/details") + params, {credentials: 'include'})
        .then(response => response.json())
        .then(data => {
            this.app.setState({orderDetails: data});
            this.setState({loaded: true, is_error: false, reloadData: false});
        })
        .catch(error => this.setState({error: error, loaded:true, is_error: true, reloadData: false}));
    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate() {
        this.reloadData();
    }

    handleChange(event) {
        var data = this.state.data;
        if(data==null) data = {};
        data[event.target.id] = event.target.value;
        this.setState({data: data});
    }

    handleSearchButton() {
        this.setState({inSearch: true});
    }

    handleNextButton() {
        if(this.app.breadCrumb != null) {
            if(this.app.state.action.id === "edit") {
                this.app.breadCrumb.setStep(this.app.steps[5]);  
            } else 
                this.app.breadCrumb.setStep(this.app.steps[4]);  
        }          
    }

    handleReloadButtonClick() {
        this.setState({reloadData: true, is_error: false, loaded: false});
        this.forceUpdate();
    }

    handleDeleteButton(item) {
        var orderDetails = this.app.state.orderDetails;
        orderDetails.splice( orderDetails.indexOf(item), 1);
        this.app.setState({orderDetails: orderDetails});
        this.forceUpdate();
    }

    addItem(item) {
        var items = this.app.state.orderDetails;
        item["id_createUser"] = this.app.state.user.id;
        items.push(item);
        this.app.setState({orderDetails: items});
        setTimeout(function() {
            var objDiv = document.getElementById("Order-details");
            objDiv.scrollTop = objDiv.scrollHeight;
            document.getElementById("DetailsRightCol").scrollTop = objDiv.scrollHeight;
            document.getElementById("DetailsRightCol").scrollTop = 0;
            
        }, 600);
        this.forceUpdate();
    }

    render() {
        return (
            <div className="OrderDetails">
                <div className="LeftCol">
                    {this.renderOrderHeader()}
                    {this.renderOrderDetails()}
                </div>
                <div className="RightCol" id="DetailsRightCol">
                    <DetailSelector app={this.app} details={this} />
                </div>
            </div>
        );
    }

    renderOrderHeader() {
        var orderButtonText = "ALÁÍRÁS";
        if(this.app.state.action.id==="edit") orderButtonText = "MENTÉS";
        return (
            <div className="Order-header">
            <table cellPadding="0" cellSpacing="0">
            <tbody>
                <tr>
                    <td className="Order-header-td-left">
                        <div className="Order-header-line-orderno">
                            <input placeholder="R.SZÁM" readOnly={true} onChange={this.handleChange.bind(this)} type="text" name="order_number" id="order_number" value={this.app.state.order.order_number} />
                            <input placeholder="NÉV" onChange={this.handleChange.bind(this)} type="text" name="contact_name" id="contact_name" value={this.app.state.order.contact_name} />
                        </div>
                    </td>
                    <td className="Order-header-td-right" rowSpan={3}>
                        <div className="Order-header-line">
                            {this.app.formattedDeadline()}
                            {(this.app.state.orderDetails.length>0)?(<button className="order-button" onClick={(e) => this.handleNextButton()}>{orderButtonText}</button>):""}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="Order-header-td-left">
                        <div className="Order-header-line">
                            <input placeholder="TELEFONSZÁM" readOnly={true} onChange={this.handleChange.bind(this)} type="phone" name="contact_phone" id="contact_phone" value={this.app.state.order.contact_phone} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="Order-header-td-left">
                        <div className="Order-header-line">
                            <input placeholder="CÍM" readOnly={true} onChange={this.handleChange.bind(this)} type="text" name="contact_address" id="contact_address" value={this.app.state.order.contact_address} />
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        );
    }

    renderOrderDetails() {
        if(this.state.is_error) {
            return (
                <div className="Order-details"><span className="error-message">Hiba az adatok betöltése során</span><br /><button onClick={this.handleReloadButtonClick.bind(this)}>Újra</button></div>
            );
        }

        if(!this.state.loaded) {
            return (
              <div className="Order-details"><img src="animation.gif" alt="Betöltés alatt..." /></div>
            );
        }
        var toDisplay = "";
        var counter = 0;
        toDisplay = this.app.state.orderDetails.map(function(d) {
            var notes = "" + d.notes + "";

            if(d.text_selected===true) {
                notes = "Felirat: " + notes;
            }

            if(d.number_selected!==true && d.color_selected === true) {
                notes = d.aux_color.toLowerCase() + ((notes.length>0) ? "; " + notes : "");
            }

            var titlePrefix = "";
            var titlePostfix = "";
            if(d.number_selected === true) {
                if(d.color_selected === true) {
                    notes = d.aux_color.toLowerCase() + " \"" + d.number + "\"" + ((notes.length>0) ? "; " + notes : "");
                    titlePrefix = (<span className="item-number" style={{color: d.colorInRgb}}>{""+d.number+""}&nbsp;</span>);
                    titlePostfix = " " + d.aux_color.toLowerCase();
                } else {
                   notes = "" + d.number + ((notes.length>0) ? "; " + notes : "");
                   titlePrefix = (<span className="item-number">{""+d.number+""}&nbsp;</span>);
                }
            }

            if(d.quantity_selected === true) {
                notes = d.quantity + "db" + ((notes.length>0) ? "; " + notes : "");
            }
            counter++;


            return (
                <div className="Detail-Item" key={"orderDetailLine" + counter + ""}>
                    {(d.is_cake && counter!==1)?(<hr />):""}
                    <div className="item-delete">
                        <button onClick={(e) => this.handleDeleteButton(d)}>X</button>
                    </div>
                    <span className="item-image"><img src={d.aux_image} alt="no" width={80} /></span>
                    <div className="item-priceblock">
                        <span className="item-price">{d.sumPrice} Ft</span>
                        <span className="item-barcode"><Barcode width={1} height={30} value={d.barcode} /></span>
                    </div>
                    <span className="item-name">{titlePrefix}{d.item_name}{titlePostfix}</span>
                    <span className="item-notes">{notes}</span>
                </div>
            );
        }.bind(this));
        
        return (
            <div className="Order-details" id="Order-details">
                {toDisplay}
            </div>
        );
    }

  shouldComponentUpdate(nextProps, nextState) {
    if(
            nextState.loaded !== this.state.loaded
    ) 
        return true;
    
    if(nextState.orderDetails !== undefined && this.state.orderDetails !== undefined) {
        if(nextState.orderDetails === [] && this.state.orderDetails === [])
            return false;
        if(nextState.orderDetails!==[] && this.state.orderDetails!==[] && nextState.orderDetails.length===this.state.orderDetails.length) {
            return false;
        }
    }

    return true;
  }

}

export default Details;
