import React, { Component } from 'react';
import Dataconnection from './../tools/dataconnection';

class DetailSelector extends Component {
    app = null;
    details = null;

    constructor(props) {
        super();

        if(props.app!==undefined) this.app = props.app;
        if(props.details!==undefined) this.details = props.details;

        this.state = {display: "productClass", 
        id_parentProductClass: 0,
            productClass: [], id_productClass: 0, productClassLoaded: false, productClasses: [],
            product: [], id_product: 0, productsLoaded: false, products: [],
            cakeFormat: [], id_cakeFormat: 0, cakeFormatLoaded: false, cakeFormats: [],
            selectedColor: [{id: 0, name: "Fekete", colorCode: "#000000" }], id_color: 0, colorsLoaded: false, colors: [],
            notes: "", numberSelected: 0, quantitySelected: 1, cakeAccessoriesSelector: false,
            screenNavigation: [],
            is_error: false };
    }

    render() {
        return (
            <div className="DetailSelector">
                {this.renderBackButton()}
                {(this.state.display==="productClass")?this.renderProductClassSelector():""}
                {(this.state.display==="product")?this.renderProductSelector():""}
                {(this.state.display==="cakeFormat")?this.renderCakeFormatSelector():""}
                {(this.state.display==="note")?this.renderNoteSelector():""}
                {(this.state.display==="numberSelector")?this.renderNumberSelector():""}
                {(this.state.display==="quantitySelector")?this.renderQuantitySelector():""}
                {(this.state.display==="colorSelector")?this.renderColorSelector():""}
            </div>
        );
    }

    reloadData() {
        if(this.state.display === "productClass" && this.state.productClassLoaded) return;
        if(this.state.display === "product" && this.state.productsLoaded) return;
        if(this.state.display === "cakeFormat" && this.state.cakeFormatLoaded) return;
        if(this.state.display === "colorSelector" && this.state.colorsLoaded) return;
        if(this.state.display === "numberSelector" && this.state.colorsLoaded) return;
        if(this.state.display === "quantitySelector" && this.state.colorsLoaded) return;
        if(this.state.display === "note" && this.state.colorsLoaded) return;

        //Get data
        switch(this.state.display) {
            case "productClass":
                var url = "productClass";
                //if(this.state.cakeAccessoriesSelector) url += "/accessories";
                if(this.state.id_parentProductClass !== 0) url += "/" + this.state.id_parentProductClass + "";

                fetch(Dataconnection.getUrl(url), {credentials: 'include'})
                .then(response => response.json())
                .then(data => {
                    this.setState({productClasses: data, productClassLoaded: true, is_error: false});
                })
                .catch(error => this.setState({productClasses: [], productClassLoaded:true, is_error: true}));
            break;
            case "product":
                fetch(Dataconnection.getUrl("product") + "/" + this.state.id_productClass + "", {credentials: 'include'})
                .then(response => response.json())
                .then(data => {
                    this.setState({products: data, productsLoaded: true, is_error: false});
                })
                .catch(error => this.setState({products: [], productsLoaded:true, is_error: true}));
            break;
            case "cakeFormat":
                fetch(Dataconnection.getUrl("cakeFormat"), {credentials: 'include'})
                .then(response => response.json())
                .then(data => {
                    this.setState({cakeFormats: data, cakeFormatLoaded: true, is_error: false});
                })
                .catch(error => this.setState({cakeFormats: [], cakeFormatLoaded:true, is_error: true}));
            break;
            case "numberSelector": case "colorSelector": case "note": case "quantitySelector":
                fetch(Dataconnection.getUrl("colors"), {credentials: 'include'})
                .then(response => response.json())
                .then(data => {
                    this.setState({colors: data, colorsLoaded: true, is_error: false});
                })
                .catch(error => this.setState({colors: [], colorsLoaded:true, is_error: true}));
            break;
            default:
            break;
        }
    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate() {
        this.reloadData();
    }

    addToNaviLine(display) {
        this.state.screenNavigation.push(display);
    }

    handleReloadButtonClick() {
        switch(this.state.display) {
            case "productClass":
                this.setState({productClassLoaded: false});
            break;
            case "product":
                this.setState({productsLoaded: false});
            break;
            case "cakeType":
                this.setState({cakeFormatLoaded: false});
            break;
            case "colorSelector":
                this.setState({colorsLoaded: false});
            break;
            case "numberSelector": case "note": case "quantitySelector":
                this.setState({colorsLoaded: false});
            break;
            default:
            break;
        }
        this.reloadData();
    }

    handleProductClassSelection(pc) {
        if(pc.id === -1) {
            this.setState({display: "productClass", cakeAccessoriesSelector: true, productClassLoaded: false});
            return;
        }
        if(pc.hasChild !== false) {
            this.state.screenNavigation.push(this.state.display);
            this.setState({display: "productClass", id_parentProductClass: pc.id, productClassLoaded: false});
            return;
        }
        this.state.screenNavigation.push(this.state.display);
        this.setState({productClass: pc, id_productClass: pc.id, display: "product", productsLoaded: false});
    }

    handleProductSelection(product) {
        var nextDisplay = "productClass";

        if(product.is_cake) nextDisplay="note";
        else if(product.flag_numberSelectionNeeded) nextDisplay="numberSelector";
        else if(product.flag_quantitySelectionNeeded) nextDisplay="quantitySelector";
        else if(product.flag_noteNeeded) nextDisplay="note";
        else if(product.flag_colorNeeded) nextDisplay="colorSelector";
        
        this.state.screenNavigation.push(this.state.display);

        this.setState({id_cakeFormat: product.id_cakeFormat, cakeFormat: [{id: product.id_cakeFormat, image_url: product.aux_cakeFormatImage, name: "", slices: 0}], id_product: product.id, product: product, display: nextDisplay, quantitySelected: 1, cakeAccessoriesSelector: false, id_parentProductClass: 0});

        if(nextDisplay=== "productClass") 
        {
            this.addItemToDetails(); 
            this.setState({display: "productClass", cakeAccessoriesSelector: false, id_parentProductClass: 0, productClassLoaded: false});  
        }

        if(nextDisplay === "quantitySelector") {
            this.setState({quantitySelected: 0});
        }
    }

    handleCakeFormatSelection(cakeType) {
        this.state.screenNavigation.push(this.state.display);
        this.setState({id_cakeFormat: cakeType.id, cakeFormat: cakeType, display: "note"});
    }

    handleNoteChange(event) {
        this.setState({notes: event.target.value});
    }

    handleNoteDoneClick() {
        //Check notes if needed
        if(this.state.product.flag_noteNeeded) {
            if(this.state.notes === "") {
                alert("Kérem először adja meg a szükséges megjegyzéseket!");
                return;
            }
        }

        var accessories = this.state.cakeAccessoriesSelector;

        //Check product type
        if(this.state.product.is_cake) {
            accessories = true;
        }

        //Add item 
        this.addItemToDetails();   

        //If cake then show accessories
        if(accessories) {
            this.setState({display: "productClass", cakeAccessoriesSelector: true, id_parentProductClass: 4, productClassLoaded: false});
        }
    }

    handleBackButton() {
        if(this.state.screenNavigation.length === 0 && this.state.cakeAccessoriesSelector === false) return;
        var navi = this.state.screenNavigation;
        var prevScreenName = "productClass";
        if(navi.length>0)
            prevScreenName = navi.pop();

        //Clear current values
        switch(this.state.display) {
            case "product":
          
            this.setState(
                {display: "productClass", 
                    productClass: [], id_productClass: 0,
                    product: [], id_product: 0, productsLoaded: false, products: [], productClassLoaded: false, //id_parentProductClass: 0,
                    cakeFormat: [], id_cakeFormat: 0, cakeFormatLoaded: false, cakeFormats: [],
                    notes: "", numberSelected: 0, quantitySelected: 1,screenNavigation: navi,
                    is_error: false });
            break;
            case "productClass":
                this.setState(
                {display: "productClass", 
                    productClass: [], id_productClass: 0, cakeAccessoriesSelector: false, productClassLoaded: false,
                    product: [], id_product: 0, productsLoaded: false, products: [],
                    cakeFormat: [], id_cakeFormat: 0, cakeFormatLoaded: false, cakeFormats: [],
                    notes: "", numberSelected: 0, quantitySelected: 1,screenNavigation: [], id_parentProductClass: 0,
                    is_error: false });
            break;
            case "cakeFormat":
                this.setState(
                {display: prevScreenName, 
                    product: [], id_product: 0, productsLoaded: false, products: [],
                    cakeFormat: [], id_cakeFormat: 0, cakeFormatLoaded: false, cakeFormats: [],
                    notes: "", numberSelected: 0, quantitySelected: 1,screenNavigation: navi,
                    is_error: false });
            break;
            default: 
                if(this.state.cakeAccessoriesSelector) {
                    this.setState({cakeAccessoriesSelector: false, id_parentProductClass: 0, screenNavigation: []});
                    break;
                }

                this.setState({display: prevScreenName, screenNavigation: navi});
            break;
        }
    }

    handleNumberSelectorClicked(number) {
        this.setState({numberSelected: "" + number + ""});
    }

    handleQuantitySelectorClicked(number) {
        var numString = "" + this.state.quantitySelected + "" + number + "";

        if(number==="«") {
            if(this.state.quantitySelected.toString().length>1) {
                numString = this.state.quantitySelected.toString().substr(0,this.state.quantitySelected.toString().length-1);
            } else {
                numString = "0";
            }
        }

        if(number === ",") {
            numString = this.state.quantitySelected.toString() + ".";
            this.setState({quantitySelected: parseFloat(numString)});
            if(parseFloat(numString)>=0) {
                this.setState({quantitySelected: numString});
            }

        } else 
        if(parseFloat(numString)>=0) {
            this.setState({quantitySelected: parseFloat(numString)});
        }
    }

    handleColorClick(color) {
        this.setState({
            selectedColor: color, id_color: color.id, displaySaveButton: true
        });
        document.getElementById("SelectedNumber").style = "color: " + color.colorInRgb;

        //document.getElementById("SelectedNumber").style.addItem("color", color.colorInRgb);
    }

    addItemToDetails() {
        //Create order detail item
        var quantity = 1, quantity_selected = false;
        if(this.state.product.flag_quantitySelectionNeeded===true) {
            quantity = this.state.quantitySelected;
            quantity_selected = true;
        }
        //alert(this.state.product.image_url);
        var item = {
            id: -1,
            id_order: this.app.state.order.id,
            id_item: this.state.id_product,
            id_color: this.state.id_color,
            color: this.state.selectedColor,
            aux_color: this.state.selectedColor.name,
            number: this.state.numberSelected,
            number_selected: this.state.product.flag_numberSelectionNeeded === true,
            color_selected: this.state.product.flag_colorNeeded && this.state.id_color !== 0,
            text_selected: this.state.product.flag_noteNeeded,
            quantity_selected: quantity_selected,
            id_itemType: this.state.id_cakeFormat,
            item_name: this.state.product.name,
            item_type: this.state.cakeFormat.name,
            notes: this.state.notes,
            barcode: this.state.product.barcode,
            id_createUser: this.app.state.user.id,
            is_cake: this.state.product.is_cake,
            unitPrice: this.state.product.unitPrice,
            quantity: quantity,
            sumPrice: this.state.product.unitPrice*quantity,
            aux_image: (this.state.product.is_cake) ? this.state.product.aux_cakeFormatImage : this.state.product.image_url,
            colorInRgb: (this.state.id_color!==0) ? this.state.selectedColor.colorInRgb : "#000000"
        };

        //Save to details
        this.details.addItem(item);

        //Reset state
        this.setState(
        {display: "productClass", 
            productClass: [], id_productClass: 0, productClassLoaded: false, productClasses: [],
            product: [], id_product: 0, productsLoaded: false, products: [],
            cakeFormat: [], id_cakeFormat: 0, cakeFormatLoaded: false, cakeFormats: [],
            selectedColor: [{id: 0, name: "Fekete", colorCode: "#000000" }], id_color: 0, colorsLoaded: false, colors: [],
            notes: "", numberSelected: 0, quantitySelected: 1,screenNavigation: [],
            is_error: false });
    }

    renderProductClassSelector() {
        if(this.state.is_error) {
            return (
                <div><span className="error-message">Hiba az adatok betöltése során</span><br /><button onClick={this.handleReloadButtonClick.bind(this)}>Újra</button></div>
            );
        }
        if(!this.state.productClassLoaded) {
            return (<div><img src="animation.gif" alt="Betöltés alatt..." /></div>);
        }

        var toDisplay = "";
        toDisplay = this.state.productClasses.map(function(d) {
            return (
                <div key={"detailSelectorProductClass_" + d.id} className="ProductClass-Item" onClick={function() { this.handleProductClassSelection(d) }.bind(this)}>
                    <div className="ProductClass-image"><img src={d.image_url} alt="no" /></div>
                    <span className="item-name">{d.name}</span>
                </div>
            );
        }.bind(this));
    
        return (
            <div className="ProductClasses">
            {(this.state.cakeAccessoriesSelector)?(<h3>KIEGÉSZÍTŐK</h3>):""}
            {toDisplay}
            </div>
        );
    }

    renderProductSelector() {
        if(this.state.is_error) {
            return (
                <div><span className="error-message">Hiba az adatok betöltése során</span><br /><button onClick={this.handleReloadButtonClick.bind(this)}>Újra</button></div>
            );
        }
        if(!this.state.productsLoaded) {
            return (<div><img src="animation.gif" alt="Betöltés alatt..." /></div>);
        }

        var toDisplay = "";
        toDisplay = this.state.products.map(function(d) {
            return (
                <div key={"detailSelectorProduct_" + d.id} className="ProductClass-Item" onClick={function() { this.handleProductSelection(d) }.bind(this)}>
                    <div className="ProductClass-image"><img src={d.image_url} alt="no" /></div>
                    <span className="item-name">{d.name}</span>
                </div>
            );
        }.bind(this));
    
        return (
            <div className="ProductClasses">
            <h3>{(this.state.productClass.id!==1)?"TERMÉKVÁLASZTÓ":"TORTA ÍZE"}</h3>
            {toDisplay}
            </div>
        );

    }

    renderCakeFormatSelector() {
        if(this.state.is_error) {
            return (
                <div><span className="error-message">Hiba az adatok betöltése során</span><br /><button onClick={this.handleReloadButtonClick.bind(this)}>Újra</button></div>
            );
        }
        if(!this.state.cakeFormatLoaded) {
            return (<div><img src="animation.gif" alt="Betöltés alatt..." /></div>);
        }

        var toDisplay = "";
        toDisplay = this.state.cakeFormats.map(function(d) {
            return (
                <div key={"detailSelectorCakeFormat_" + d.id} className="ProductClass-Item" onClick={function() { this.handleCakeFormatSelection(d) }.bind(this)}>
                    <div className="ProductClass-image"><img src={d.image_url} alt={d.name} /></div>
                    <span className="item-name">{d.name}</span>
                </div>
            );
        }.bind(this));
    
        return (
            <div className="ProductClasses">
            <h3>{this.state.product.name + " szeletszám"}</h3>
            {toDisplay}
            </div>
        );
    }

    renderColorSelector() {
        if(this.state.is_error) {
            return (
                <div><span className="error-message">Hiba az adatok betöltése során</span><br /><button onClick={this.handleReloadButtonClick.bind(this)}>Újra</button></div>
            );
        }
        if(!this.state.colorsLoaded) {
            return (<div><img src="animation.gif" alt="Betöltés alatt..." /></div>);
        }

        var toDisplay = "";
        toDisplay = this.state.colors.map(function(d) {
            var className = "ProductClass-Item-Default";
            if(d.id === this.state.id_color) className = "ProductClass-Item-Selected";
            return (
                <div key={"detailSelectorColor_" + d.id} className={className} style={{color: "white", backgroundColor: d.colorInRgb}} onClick={function() { this.handleColorClick(d) }.bind(this)}>
                    <span className="item-name">{d.name}</span>
                </div>
            );
        }.bind(this));

        var extension = "";

        if(this.state.display==="colorSelector") {
            extension = (<span className="SelectedNumber">|</span>);
        }
    
        return (
            <div className="ProductClasses">
                {extension}
                {toDisplay}
            </div>
        );
    }

    renderNumberSelector() {
        var numpad = "";
        numpad = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(function(d) {
            return (
                <div key={"numberSelector" + d + ""} className="numberSelector-Item">
                    <button  onClick={function() { this.handleNumberSelectorClicked(d) }.bind(this)}>{d}</button>
                </div>
            );
        }.bind(this));

        //alert(this.state.selectedColor.colorInRgb);

        return (
            <div className="NumberSelector">
            <h3>Szám választása</h3>
            <div className="NumberSelectorTable">
                <div className="NumberSelectorRow">
                    <div className="SelectedNumber" id="SelectedNumber"><span style={[{backgroundColor: this.state.selectedColor.colorInRgb}]}>{this.state.numberSelected}</span></div>
                    <div className="NumPad">
                        {numpad}
                    </div>
                </div>
            </div>                
            {(this.state.product.flag_colorNeeded)?this.renderColorSelector():""}
            </div>
        );
    }

    renderQuantitySelector() {
        var numpad = "";
        numpad = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, ",", "«"].map(function(d) {
            return (
                <div key={"numberSelector" + d + ""} className="numberSelector-Item">
                    <button  onClick={function() { this.handleQuantitySelectorClicked(d) }.bind(this)}>{d}</button>
                </div>
            );
        }.bind(this));

        return (
            <div className="NumberSelector">
            <h3>Mennyiség megadása:</h3>
            <div className="NumberSelectorTable">
                <div className="NumberSelectorRow">
                    <div className="SelectedNumber" id="SelectedNumber">{this.state.quantitySelected}</div>
                    <div className="NumPad">
                        {numpad}
                    </div>
                </div>
            </div>                
            {(this.state.product.flag_colorNeeded)?this.renderColorSelector():""}
            </div>
        );
    }

    renderNoteSelector() {
        var placeholder = "...";
        if(this.state.product.is_cake) {
            placeholder = "Különleges kérések a tortával kapcsolatban, egyéb megjegyzések...";
        }
        if(this.state.product.flag_noteNeeded) {
            placeholder = "Szöveg a táblára, egyéb megjegyzések...";
        }
        return (
            <div className="Note-Editor">
                <h3>MEGJEGYZÉS</h3>
                <textarea id="SelectedNumber" placeholder={placeholder} name="item-note" onChange={this.handleNoteChange.bind(this)}></textarea>
                {(this.state.product.flag_colorNeeded)?this.renderColorSelector():""}
            </div>
        );
    }

    renderBackButton() {
        if(this.state.screenNavigation.length === 0 && !this.state.cakeAccessoriesSelector) return "";
        var displaySaveButton = false;
        if(this.state.display === "note") displaySaveButton = true;
        else if(this.state.display === "numberSelector" && this.state.product.flag_colorNeeded === false)
            displaySaveButton = true;
        else if(this.state.display === "numberSelector" && this.state.product.flag_colorNeeded === true && this.state.id_color !== 0)
            displaySaveButton = true;
        else if(this.state.display === "quantitySelector" && this.state.product.flag_colorNeeded===false && this.state.quantitySelected>0)
            displaySaveButton = true;
        else if(this.state.display === "quantitySelector" && this.state.product.flag_colorNeeded===true && this.state.id_color !==0 && this.state.quantitySelected>0)
            displaySaveButton = true;
        
        return (
            <div className="DetailSelector-BackButtonContainer">
            <button className="BackButton" onClick={this.handleBackButton.bind(this)}>« VISSZA</button>
            {(displaySaveButton)?(<button className="SaveButton" onClick={this.handleNoteDoneClick.bind(this)}>HOZZÁADÁS »</button>):""}
            </div>
        );
    }


  shouldComponentUpdate(nextProps, nextState) {
    
    if(nextState.display !== this.state.display) return true;
    if(nextState.productClassLoaded !== this.state.productClassLoaded) return true;
    if(nextState.productsLoaded !== this.state.productsLoaded) return true;
    if(nextState.cakeFormatLoaded !== this.state.cakeFormatLoaded) return true;
    if(nextState.id_productClass !== this.state.id_productClass) return true;
    if(nextState.id_product !== this.state.id_product) return true;
    if(nextState.numberSelected !== this.state.numberSelected) return true;
    if(nextState.quantitySelected !== this.state.quantitySelected) return true;
    if(nextState.cakeAccessoriesSelector !== this.state.cakeAccessoriesSelector) return true;
    if(nextState.is_error !== this.state.is_error) return true;
    if(nextState.id_color !== this.state.id_color) return true;
    if(nextState.colorsLoaded !== this.state.colorsLoaded) return true;

    return false;
  }

}
export default React.memo(DetailSelector)
//export default DetailSelector;
