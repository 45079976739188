import React, { Component } from 'react';
import Barcode from 'react-barcode';
import SigningTable from './signingTable';

class Signing extends Component {
    app = null;
    imageData = null;

    constructor(props) {
        super();
        if(props.app!==undefined) this.app = props.app;

        this.state = {done: false, descriptionVisible: false};
    }

    handleNextButton() {
        if(this.app.signing === null) {
            alert("Kérem először írja alá a rendelést!");
            return;
        }
        if(this.app.breadCrumb != null) {
            this.app.breadCrumb.setStep(this.app.steps[5]);  
        }          
    }

    handleDescriptionClick() {
        this.setState({descriptionVisible: !this.state.descriptionVisible});
    }

    render() {
        return (
            <div className="OrderDetails">
                {this.renderDescription()}
                <div className="LeftCol">
                    {this.renderOrderHeader()}
                    {this.renderOrderDetails()}
                </div>
                <div className="RightCol" id="DetailsRightCol">
                    <div className="hidden">
                        <button className="next-button" onClick={this.handleDescriptionClick.bind(this)}>Adatkezelési és általános szerződési feltételek</button>
                    </div>
                    <div className="signing-description">
                        Aláírásommal elismerem és tudomásul veszem az <br /> "Adatkezelési és általános szerződési feltételekben" foglaltakat.
                    </div>
                    <div className="SigningTable-container">
                        <SigningTable app={this.app} signing={this} />
                        {((this.state.done)?(<button className="next-button" onClick={this.handleNextButton.bind(this)}>TOVÁBB</button>):"")}
                    </div>
                </div>
            </div>
        );
    }

    renderDescription() {
        if(this.state.descriptionVisible === false) return "";

        return(
            <div className="description-overlay">
                <div className="description-inner">
                    <div className="description-close"><button onClick={this.handleDescriptionClick.bind(this)}>Bezárás</button></div>
                    <div className="description-padding">
                        Felhasználási feltételek...
                    </div>
                </div>
            </div>
        );
    }

    renderOrderHeader() {
        return (
            <div className="Order-header">
            <table cellPadding="0" cellSpacing="0">
            <tbody>
                <tr>
                    <td className="Order-header-td-left">
                        <div className="Order-header-line-orderno">
                            <input placeholder="R.SZÁM" readOnly={true} type="text" name="order_number" id="order_number" value={this.app.state.order.order_number} />
                            <input placeholder="NÉV" readOnly={true} type="text" name="contact_name" id="contact_name" value={this.app.state.order.contact_name} />
                        </div>
                    </td>
                    <td className="Order-header-td-right" rowSpan={3}>
                        <div className="Order-header-line">
                        {this.app.formattedDeadline()}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="Order-header-td-left">
                        <div className="Order-header-line">
                            <input placeholder="TELEFONSZÁM" readOnly={true} type="phone" name="contact_phone" id="contact_phone" value={this.app.state.order.contact_phone} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="Order-header-td-left">
                        <div className="Order-header-line">
                            <input placeholder="CÍM" readOnly={true} type="text" name="contact_address" id="contact_address" value={this.app.state.order.contact_address} />
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        );
    }

    renderOrderDetails() {
        
        var toDisplay = "";
        var counter = 1;
        toDisplay = this.app.state.orderDetails.map(function(d) {
            var notes = "" + d.notes + "";

            if(d.text_selected===true) {
                notes = "Felirat: " + notes;
            }

            if(d.number_selected!==true && d.color_selected === true) {
                notes = d.aux_color.toLowerCase() + ((notes.length>0) ? "; " + notes : "");
            }

            var titlePrefix = "";
            var titlePostfix = "";
            if(d.number_selected === true) {
                if(d.color_selected === true) {
                    notes = d.aux_color.toLowerCase() + " \"" + d.number + "\"" + ((notes.length>0) ? "; " + notes : "");
                    titlePrefix = (<span className="item-number" style={{color: d.colorInRgb}}>{""+d.number+""}&nbsp;</span>);
                    titlePostfix = " " + d.aux_color.toLowerCase();
                } else {
                   notes = "" + d.number + ((notes.length>0) ? "; " + notes : "");
                   titlePrefix = (<span className="item-number">{""+d.number+""}&nbsp;</span>);
                }
            }

            if(d.quantity_selected === true) {
                notes = d.quantity + "db" + ((notes.length>0) ? "; " + notes : "");
            }
            counter++;


            return (
                <div className="Detail-Item" key={"orderDetailLine" + counter + ""}>
                    {(d.is_cake && counter!==1)?(<hr />):""}
                    <span className="item-image"><img src={d.aux_image} alt="no" width={80} /></span>
                    <div className="item-priceblock">
                        <span className="item-price">{d.sumPrice} Ft</span>
                        <span className="item-barcode"><Barcode width={1} height={30} value={d.barcode} /></span>
                    </div>
                    <span className="item-name">{titlePrefix}{d.item_name}{titlePostfix}</span>
                    <span className="item-notes">{notes}</span>
                </div>
            );
        });
        
        return (
            <div className="Order-details" id="Order-details">
                {toDisplay}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.done !== nextState.done) return true;
        if(this.state.descriptionVisible !== nextState.descriptionVisible) return true;
        
        return false;
      }
}

export default Signing;
