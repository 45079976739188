import React, { Component } from 'react';

class SigningTable extends Component {
    app = null;
    signing = null;
    touchStarted = false;
    canvas = null;
    ctx = null;
    prevX = -1;
    prevY = -1;
    drawCounter = 0;
    skip = false;
    counter = 0;

    constructor(props) {
        super();
        if(props.app!==undefined) this.app = props.app;
        if(props.signing!==undefined) this.signing = props.signing;
    }

    componentDidMount() {
        this.canvas = this.refs.canvas;
        this.ctx = this.canvas.getContext("2d");

        if(this.app.state.signing !== undefined && this.app.state.signing !== null) {
            var image = new Image();
            image.onload = function() {
                this.ctx.drawImage(image, 0, 0);
            }.bind(this);
            image.src = this.app.state.signing;
        }
    }

    handleTouchSart(e) {
        e.preventDefault();
        this.touchStarted = true;        
        this.ctx.fillStyle = "none";
        this.ctx.lineWidth = 3;        
        this.ctx.strokeStyle = "#002fde";
        this.skip = false;
        this.counter = 0;
    }

    handleTouchEnd(e) {
        e.preventDefault();
        this.skip = false;
        this.touchStarted = false;
        this.prevX = -1;
        this.prevY = -1;
        if(this.signing.state.done) {
            this.app.state.signing = this.canvas.toDataURL();
        }
    }

    handleTouchMove(e) 
    {
        e.preventDefault();
        this.skip = !this.skip;
        if(this.skip) return;
        if(this.touchStarted)
            this.getPosition(e); 
    }
    
    getPosition(event){
        var rect = this.canvas.getBoundingClientRect();
        var x = event.clientX - rect.left;
        var y = event.clientY - rect.top;
        if(event.changedTouches !== undefined) {
            x = event.changedTouches[0].pageX - rect.left;
            y = event.changedTouches[0].pageY - rect.top;
            
        }
        this.drawCoordinates(x,y);

        if(this.drawCounter<10) {
            this.drawCounter++;
        } else {
            if(!this.signing.state.done && this.signing !== null) {
                this.signing.setState({done: true});
            }
        }
   }
   
   drawCoordinates(x,y){	
        if(this.counter === 0)
            this.ctx.beginPath();
        
        this.counter++;

        if(this.prevX===-1 && this.prevY===-1) {
            this.ctx.moveTo(x, y);
        } else {
            this.ctx.moveTo(this.prevX, this.prevY);
        }   
        this.prevX = x;
        this.prevY = y;
        this.ctx.lineTo(x,y);

        this.ctx.stroke();
        if(this.counter >= 30) {
            this.ctx.closePath();
            this.counter = 0;
        }
    }

    handleClearButton() {
        this.ctx.clearRect(0, 0, 600, 200);
    }

    render() {
        return (
            <div className="SigningTable">
                <h4>Aláírás</h4>
                <center>
                <button className="clear-button" onClick={this.handleClearButton.bind(this)}>ÚJRA</button>
                </center>
                <canvas ref="canvas" onMouseDown={this.handleTouchSart.bind(this)} onMouseUp={this.handleTouchEnd.bind(this)} onMouseMove={this.handleTouchMove.bind(this)}  onTouchEnd={this.handleTouchEnd.bind(this)} onTouchMove={this.handleTouchMove.bind(this)} onTouchStart={this.handleTouchSart.bind(this)}  width={400} height={200} />
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state === null && nextState !== null) return true;
        if(this.state !== null && nextState === null) return true;
        if(this.state === null && nextState === null) return false;
        if(this.state.done === null && nextState.done !== null) return true;
        if(this.state.done !== null && nextState.done === null) return true;
        if(this.state.done === null && nextState === null) return false;
        if(this.state.done !== nextState.done) return true;

        return false;
      }
}

export default SigningTable;
