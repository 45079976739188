import React, { Component } from 'react';
import './App.css';

import Breadcrumb from './tools/breadcrumb';
import UserSelector from './modules/userselector';
import Actions from './modules/actions';
import Order from './modules/order';
import Details from './modules/details';
import Signing from './modules/signing';
import Print from './modules/print';

class App extends Component {
  breadCrumb = null;
  userSelector = null;
  headers = null;

  steps = [
    {step: 0, name: "Felhasználó", url: ""},
    {step: 1, name: "Művelet", url:"actions"},
    {step: 2, name: "Rendelés", url:"order"},
    {step: 3, name: "Tételek", url:"details"},
    {step: 4, name: "Aláírás", url:"signing"},
    {step: 5, name: "Nyomtatás", url:"print"}
  ];

  constructor(props) {
    super();
    
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    this.headers = reqHeaders;
    
    this.state = {user: null, step: this.steps[0], action: null, order: null, orderDetails: [], counter: 0, signing: null};
  }

  resetState() {
    this.setState({
      user: null, step: this.steps[0], action: null, order: null, orderDetails: [], counter: 0, signing: null
    });
  }

  formattedDeadline() {
    if(this.state.order === null) return "";
    if(this.state.order.deadline === null || this.state.order.deadline === "") return "";
    var deadlineDate = new Date(this.state.order.deadline);
    
    var timeString = App.getTimeString(deadlineDate);

    var dayName = "";
    var days = ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"];
    dayName = days[deadlineDate.getDay()];
    return (
      <span>
      {deadlineDate.toLocaleDateString()}<br/>{dayName}<br />{timeString}
      </span>
    );
  }

  static strright(str, chr)
  {
    return str.slice( -( chr ) );
  }

  static getTimeString(deadlineDate)
  {
    var timeString = App.strright("00" + (deadlineDate.getHours()-1).toString(),2) + ":" + App.strright("00" + deadlineDate.getMinutes().toString(), 2);
    return timeString;
  }
  static shortFormattedDeadline(date) {
    if(date === null || date === "") return "";
    var deadlineDate = new Date(date);
    var timeString = App.getTimeString(deadlineDate);
    return (
      <span>{deadlineDate.toLocaleDateString()}&nbsp;{timeString}</span>
    );
  }

  render() {
    //alert(this.state.user);
    return (
      <div className="App">
        <header className="App-header">
          <Breadcrumb app={this} steps={this.steps} step={this.state.step}></Breadcrumb>
        </header>
        {this.renderStatusBar()}
        <div className="App-content">
            {this.renderMainContent()}
        </div>
      </div>
    );
  }

  refresh() {
    this.setState({counter: this.state.counter+1});
  }

  renderStatusBar() {
    //User
    var userData = "";
    if(this.state.user !== null) {
      userData = (<span>Rögzítő: {this.state.user.fullname}&nbsp;</span>);
    }
    //Action
    var selectedAction = "";
    if(this.state.action !== null) {
      if(userData!=="")
      selectedAction = (<span>Művelet: {this.state.action.name}&nbsp;</span>);
    }

    var selectedOrder = "";
    if(this.state.order !== null) {
      if(this.state.order.id !== undefined) {
        if(this.state.order.id !== 0) {
          selectedOrder = (<span>Rendelés: {this.state.order.order_number}: {this.state.order.contact_name}</span>)
        }
      }
    }
    
    return (
      <div className="App-statusbar">
        {userData}{selectedAction}{selectedOrder}
      </div>
    );
  }

  renderMainContent() {
    var url = "";
    //alert(this.state.step.url);
    url = this.state.step.url;
    switch(url) {
      case "": return (<UserSelector app={this} user={this.state.user} />);
      case "actions": return (<Actions app={this} user={this.state.action} />);
      case "order": return (<Order app={this} user={this.state.user} action={this.state.action} order={this.state.order} />);
      case "details": return (<Details app={this} loaded={(this.state.orderDetails.length>0)}></Details>);
      case "signing": return (<Signing app={this}></Signing>);
      case "print": return (<Print app={this}></Print>);
      default: return (<div>...</div>);
    }
    
  }

  componentDidMount() {
    //alert(this.breadCrumb);  
  }

  shouldComponentUpdate(nextProps, nextState) {
    //this.state = {user: null, step: this.steps[0], action: null, order: null, orderDetails: [], counter: 0, signing: null};
    if(this.state.step.step !== nextState.step.step) return true;
    if(this.state.action === null && nextState.action !== null) return true;
    if(this.state.action !== null && nextState.action === null) return true;
    if(this.state.action !== null)
      if(this.state.action.id !== nextState.action.id) return true;

    return false;

  }
}

export default App;
