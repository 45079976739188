import React, { Component } from 'react';
import Dataconnection from './../tools/dataconnection';
import App from '../App';

class Order extends Component {
    app = null;
    constructor(props) {
        super();

        var orderData = {order_number: "", contact_name: "", contact_phone: "", contact_address: "", deadline: "", id_createUser: 0};
        if(props.app!==undefined) {
            this.app = props.app;
            orderData.id_createUser = this.app.state.user.id;
        }
        if(props.order!==undefined && props.order != null) orderData = props.order;

        this.state = {data: orderData, inSearch: false, found: false};
    }
    
    handleChange(event) {
        var data = this.state.data;
        if(data==null) data = {};
        data[event.target.id] = event.target.value;
        this.setState({data: data});
    }

    handleSearchButton() {
        this.setState({inSearch: true});
    }

    handleNextButton() {
        this.app.state.order = this.state.data;
        if(this.app.breadCrumb != null) {
            this.app.breadCrumb.setStep(this.app.steps[3]);  
        }  
    }

    render() {
        return (
            <div className="Order-selector">
            {this.renderOrderHeader()}
            {this.renderSearchForm()}
            </div>
        );
    }

    renderSearchForm() {
        if(this.state.inSearch) {
            return <OrderSearch order={this} filter={this.state.data} ></OrderSearch>
        }
    }

    renderOrderHeader() {
        var searchButton = "";
        var nextButton = "";
        var action = "add";
        if(this.app!=null) action = this.app.state.action.id;
        if(action==="edit" && this.app.state.order == null) {
        searchButton = (<div className="Order-searchButton"><button onClick={this.handleSearchButton.bind(this)}>Keresés</button></div>);
        }
        var addNextButton = false;
        if(action==="add" && this.state.data.contact_name!=="" && this.state.data.contact_phone!=="" && this.state.data.deadline!=="") {
            addNextButton = true;
        }
        if(action==="edit" && this.app.state.order != null) {
            addNextButton = true;
        }

        if(addNextButton) {
            nextButton = (<button onClick={this.handleNextButton.bind(this)}>Tovább</button>);
        }

        var orderNoField = (<input placeholder="R.SZÁM" readOnly={this.app.state.order!==null} onChange={this.handleChange.bind(this)} type="text" name="order_number" id="order_number" value={this.state.data.order_number} />);

        var className = (action === "add") ? "Order-header-line-required" : "Order-header-line-orderno";
        var nameClass = (this.state.data.contact_name!=="")?"fulfilled":"";
        var dateClass = (this.state.data.deadline!=="")?"fulfilled":"";
        var phoneClass = (this.state.data.contact_phone!=="")?"fulfilled":"";
        var addressClass = (this.state.data.contact_address!=="")?"fulfilled":"";
        
        return (
            <div className="Order-header">
            <table cellPadding="0" cellSpacing="0">
            <tbody>
                <tr>
                    <td className={"Order-header-td-left-required " + nameClass}>
                        <div className={className + " " + nameClass}>
                            {(action === "add")?"":orderNoField}
                            <input placeholder="NÉV" className={nameClass} onChange={this.handleChange.bind(this)} type="text" name="contact_name" id="contact_name" value={this.state.data.contact_name} />
                        </div>
                    </td>
                    <td className={"Order-header-td-right-required " + dateClass} rowSpan={3}>
                        <div className={"Order-header-line-required "+dateClass}>
                            DÁTUM:<br />
                            <input placeholder="DÁTUM" className={dateClass} onChange={this.handleChange.bind(this)} type="datetime-local" name="deadline" id="deadline" value={this.state.data.deadline} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className={"Order-header-td-left-required " + phoneClass}>
                        <div className={"Order-header-line-required"  + phoneClass}>
                            <input placeholder="TELEFONSZÁM" className={phoneClass} onChange={this.handleChange.bind(this)} type="phone" name="contact_phone" id="contact_phone" value={this.state.data.contact_phone} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className={"Order-header-td-left" + addressClass}>
                        <div className={"Order-header-line" + addressClass}>
                            <input placeholder="CÍM" className={addressClass} onChange={this.handleChange.bind(this)} type="text" name="contact_address" id="contact_address" value={this.state.data.contact_address} />
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
                {searchButton}
                <div className="Order-NextButton">
                    {nextButton}
                </div>
            </div>
        );
    }

}

class OrderSearch extends Component {
    order = null;
    constructor(props) {
        super();
        var contactName = "";
        var contactPhone = "";
        var contactAddress = "";
        var deadline = "";
        var orderNumber = "";

        if(props.order !== undefined) this.order = props.order;

        if(props.filter !== undefined) {
            contactName = props.filter.contact_name;
            contactPhone = props.filter.contact_phone;
            contactAddress = props.filter.contact_address;
            deadline = props.filter.deadline;
            orderNumber = props.filter.order_number;
        }
       
        this.state = {
             filters: {orderNumber: orderNumber, contactName: contactName, contactPhone: contactPhone, contactAddress: contactAddress, deadline: deadline}
            ,data: {}
            ,loaded: false
            ,error: ""
            ,is_error: false
            ,selectedItem: null
        }
    }


    reloadData() {
        if(this.state.reloadData !== true && this.state.loaded) return;

        //Set filter parameters
        var params = "?filterEnabled=true";
        if(this.state.filters.contactName !== "" && this.state.filters.contactName !== undefined) {
            params += "&contactName=" + encodeURI(this.state.filters.contactName);
        }
        if(this.state.filters.contactAddress !== "" && this.state.filters.contactAddress !== undefined) {
            params += "&contactAddress=" + encodeURI(this.state.filters.contactAddress);
        }
        if(this.state.filters.contactPhone !== "" && this.state.filters.contactPhone !== undefined) {
            params += "&contactPhone=" + encodeURI(this.state.filters.contactPhone);
        }
        if(this.state.filters.orderNumber !== "" && this.state.filters.orderNumber !== undefined) {
            params += "&orderNumber=" + encodeURI(this.state.filters.orderNumber);
        }
        if(this.state.filters.deadline !== "" && this.state.filters.deadline !== undefined) {
            params += "&deadline=" + encodeURI(this.state.filters.deadline);
        }
        //Get data
        fetch(Dataconnection.getUrl("orders") + params, {credentials: 'include'})
        .then(response => response.json())
        .then(data => this.setState({data: data, loaded: true, reloadData: false, is_error: false}))
        .catch(error => this.setState({data: {}, error: error, loaded:true, reloadData: false, is_error: true}));
    }

    handleCancelButton() {
        if(this.order!==null) this.order.setState({inSearch: false});
    }

    handleSaveButton() {
        if(this.state.selectedItem==null) {
            alert("Kérem először válasszon egy rendelést!");
            return;
        }
        if(this.order!==null) {
            this.order.setState({data: this.state.selectedItem, inSearch: false});
            this.order.app.setState({order: this.state.selectedItem, orderDetails: []});
            this.order.handleNextButton();
        }
    }
    
    handleReloadButtonClick() {
      this.setState({error: "", loaded: false});
    }

    render() {
        return (
            <div className="OrderSearch">
                <div className="OrderSearch-Overlay">
                </div>
                <div className="OrderSearch-Container">
                    <div className="OrderSearch-InnerPadding">
                        {this.renderTable()}
                    </div>
                </div>
            </div>
        );
    }

    renderTable() {
        if(this.state.loaded === false) return (<div><img src="animation.gif" alt="Betöltés alatt..." /></div>);
        if(this.state.is_error === true) return (<div>Hiba a betöltés során: {this.state.error}</div>);
        if(this.state.data === null) return (<div>data is null!</div>);

        var list = this.state.data.map(function(item) {
            return (
                <OrderListItem key={"orderSearchItem" + item.id} order={this} item={item}></OrderListItem>
            );
        }.bind(this));
        return (
            <div className="OrderSearch-List-Border">
                <div className="OrderSearch-List">
                    {list}
                </div>
                <button className="CancelButton" onClick={this.handleCancelButton.bind(this)}>Mégsem</button>
                <button className="SaveButton" onClick={this.handleSaveButton.bind(this)}>Kiválasztás</button>
            </div>
        );
    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate() {
        this.reloadData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.inSearch !== nextState.inSearch) return true;
        if(this.state.found !== nextState.found) return true;
        if(this.state.loaded !== nextState.loaded) return true;
        if(this.state.is_error !== nextState.is_error) return true;
        if(this.state.orderData === undefined && nextState.orderData !== undefined) return true;
        if(this.state.orderData !== undefined && nextState.orderData === undefined) return true;
        if(this.state.orderData === undefined && nextState.orderData === undefined) return false;
        if(this.state.orderData.order_number !== nextState.orderData.order_number) return true;
        return false;
    }
}

class OrderListItem extends Component {
    constructor(props) {
        super();

        var order = null;
        var item = {};
        var selected = false;
        if(props.order !== undefined) order = props.order;
        if(props.item !== undefined) item = props.item;
        if(props.selected!== undefined) selected = props.selected;

        this.state = {order: order, item: item, selected: selected};

    }

    handleClick() {
        if(this.state.selected) {
            if(this.state.order != null)
                this.state.order.setState({selectedItem: null});
            this.setState({selected: false});
            return;
        }
        if(this.state.order != null)
            this.state.order.setState({selectedItem: this.state.item});
        this.setState({selected: true});
    }

    render() {
        var item = this.state.item;
        return (
        <div className={"OrderSearch-ListItem"+((this.state.selected)?"Selected":"")} onClick={this.handleClick.bind(this)}>
            <div className="OrderSearch-ListItem-Header">{item.order_number}: {item.contact_name} ({App.shortFormattedDeadline(item.deadline)})</div>
            <div className="OrderSearch-ListItem-Description">{item.contact_phone}</div>
        </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.selected !== nextState.selected) return true;
        if(this.state.order.order_number !== nextState.state.order.order_number) return true;
        if(this.state.item.order_number !== nextState.item.order_number) return true;

        return false;
    }

}

export default Order;
