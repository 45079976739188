import React, { Component } from 'react';

class Actions extends Component {
    app = null;
    mountedButtonCounter = 0;
    constructor(props) {
        super();

        if(props.app!==undefined) this.app = props.app;

        var actions = [
            {id: "add", name: "Rendelésfelvétel", color: "blue"},
            {id: "edit", name: "Módosítás", color: "red"}
        ];

        this.state = {actions: actions, order: null};
    }
  render() {

    return (
      <div className="Action-selector">
        {this.renderActions()}
      </div>
    );
  }

  buttonClickHandler(action) {
    if(this.app==null) return;
    this.app.setState({action: action, order: null, orderDetails: [], signing: null});
    if(this.app.breadCrumb != null) {
        this.app.breadCrumb.setStep(this.app.steps[2]);
    }
  }

  renderActions() {
    this.mountedButtonCounter = 0;
    var toDisplay = this.state.actions.map(function(action) {
        this.mountedButtonCounter++;
        return (
          <span className="black-font-div" key={"actionSelector" + action.id + ""}>
            {((this.mountedButtonCounter>1)?(<div>vagy<br /><br /></div>):"")}
            <button className={"button-color-"+action.color} onClick={() => this.buttonClickHandler(action)}>{action.name}</button>
          </span>
        );
    }.bind(this));

    return toDisplay;
  }

  shouldComponentUpdate(nextProps, nextState) {
      return true;
  }
}

export default Actions;
