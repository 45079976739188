

export default class Dataconnection {
    static isDevEnv = false;
    static urlDev = "https://localhost:5011/api/";
    //static urlDev = "https://192.168.10.183:5001/api/";
    static urlPublish = "https://mokka.azurewebsites.net/api/";

    static getUrl = function(controller = "")  {
        return ( (Dataconnection.isDevEnv) ? Dataconnection.urlDev : Dataconnection.urlPublish ) + controller; 
    }

}