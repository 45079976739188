import React, { Component } from 'react';
import Barcode from 'react-barcode';
import Dataconnection from './../tools/dataconnection';

class Print extends Component {
    app = null;

    constructor(props) {
        super();
        if(props.app!==undefined) this.app = props.app;
        this.state = {done: false, is_error: false, error : "", is_saving: false};
    }

    handleNextButton() {

        this.setState({is_saving: true});

        //Save to DB
        var order = this.app.state.order;
        order["details"] = this.app.state.orderDetails;
        if(this.app.state.signing !== null)
            order["signing"] = this.app.state.signing;

        //alert(order);
        var method = "POST";
        var urlPostfix = "";
        if(this.app.state.action.id === "edit") {
            method = "PUT";
            urlPostfix = "/" + this.app.state.order.id;
        }
        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json")
        fetch(Dataconnection.getUrl("orders" + urlPostfix), {
            credentials: 'include',
            method: method,
            headers: reqHeaders,
            body: JSON.stringify(order)
        }) 
        //.then(response => response.json())
        .then(data => {
            this.setState({done: true, is_error: false, error: "", is_saving:false});
            //Clear order data from local store
            this.app.resetState();
            this.app.breadCrumb.setState({maxStep: 0});

            //Go to first step
            if(this.app.breadCrumb != null) {
                this.app.breadCrumb.setStep(this.app.steps[0]);  
            }          
        })
        .catch(error => this.setState({error: "Hiba a mentés során" + ((error!==undefined && error!==null)?error:"") + "", done: false, is_error: true}));

    }

    handleReloadButtonClick() {
        this.setState({done: false, is_error: false, error: ""});
        this.handleNextButton();
    }

    render() {
        var buttonText = "MEGRENDELÉS ÉS NYOMTATÁS";
        var description = "";
        if(this.app.state.action.id==="edit") {
            buttonText = "MENTÉS";
            description = ""
        }
        
        return (
            <div className="OrderDetails">
                <div className="LeftCol">
                    {this.renderOrderHeader()}
                    {this.renderOrderDetails()}
                </div>
                <div className="RightCol" id="DetailsRightCol">
                    <div>{description}</div>
                    {this.renderError()}
                    {((!this.state.done && this.state.is_saving===false)?(<button className="finish-button" onClick={this.handleNextButton.bind(this)}>{buttonText}</button>):(<span>Mentés és nyomtatás folyamatban...</span>))}
                    {(this.state.is_saving)?(<img src="animation.gif" alt="Betöltés alatt..." />):""}
                </div>
            </div>
        );
    }

    renderError() {
        if(!this.state.is_error) return "";
        return (
            <div><span className="error-message">Hiba az adatok betöltése során<br />{this.state.error}</span><br /><button onClick={this.handleReloadButtonClick.bind(this)}>Újra</button></div>
        );
    }

    renderOrderHeader() {
        return (
            <div className="Order-header">
            <table cellPadding="0" cellSpacing="0">
            <tbody>
                <tr>
                    <td className="Order-header-td-left">
                        <div className="Order-header-line-orderno">
                            <input placeholder="R.SZÁM" readOnly={true} type="text" name="order_number" id="order_number" value={this.app.state.order.order_number} />
                            <input placeholder="NÉV" readOnly={true} type="text" name="contact_name" id="contact_name" value={this.app.state.order.contact_name} />
                        </div>
                    </td>
                    <td className="Order-header-td-right" rowSpan={3}>
                        <div className="Order-header-line">
                        {this.app.formattedDeadline()}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="Order-header-td-left">
                        <div className="Order-header-line">
                            <input placeholder="TELEFONSZÁM" readOnly={true} type="phone" name="contact_phone" id="contact_phone" value={this.app.state.order.contact_phone} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="Order-header-td-left">
                        <div className="Order-header-line">
                            <input placeholder="CÍM" readOnly={true} type="text" name="contact_address" id="contact_address" value={this.app.state.order.contact_address} />
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        );
    }

    renderOrderDetails() {
        
        var toDisplay = "";
        var counter = 0;
        toDisplay = this.app.state.orderDetails.map(function(d) {
            var notes = "" + d.notes + "";

            if(d.text_selected===true) {
                notes = "Felirat: " + notes;
            }

            if(d.number_selected!==true && d.color_selected === true) {
                notes = d.aux_color.toLowerCase() + ((notes.length>0) ? "; " + notes : "");
            }

            var titlePrefix = "";
            var titlePostfix = "";
            if(d.number_selected === true) {
                if(d.color_selected === true) {
                    notes = d.aux_color.toLowerCase() + " \"" + d.number + "\"" + ((notes.length>0) ? "; " + notes : "");
                    titlePrefix = (<span className="item-number" style={{color: d.colorInRgb}}>{""+d.number+""}&nbsp;</span>);
                    titlePostfix = " " + d.aux_color.toLowerCase();
                } else {
                   notes = "" + d.number + ((notes.length>0) ? "; " + notes : "");
                   titlePrefix = (<span className="item-number">{""+d.number+""}&nbsp;</span>);
                }
            }

            if(d.quantity_selected === true) {
                notes = d.quantity + "db" + ((notes.length>0) ? "; " + notes : "");
            }
            counter++;


            return (
                <div className="Detail-Item" key={"orderDetailLine" + counter + ""}>
                    {(d.is_cake && counter!==1)?(<hr />):""}
                    <span className="item-image"><img src={d.aux_image} alt="no" width={80} /></span>
                    <div className="item-priceblock">
                        <span className="item-price">{d.sumPrice} Ft</span>
                        <span className="item-barcode"><Barcode width={1} height={30} value={d.barcode} /></span>
                    </div>
                    <span className="item-name">{titlePrefix}{d.item_name}{titlePostfix}</span>
                    <span className="item-notes">{notes}</span>
                </div>
            );
        });
        
        return (
            <div className="Order-details" id="Order-details">
                {toDisplay}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.done !== nextState.done) return true;
        if(this.state.is_error !== nextState.is_error) return true;
        if(this.state.error !== nextState.error) return true;
        if(this.state.is_saving !== nextState.is_saving) return true;
        
        return false;
      }
}

export default Print;
