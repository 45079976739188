import React, { Component } from 'react';
import Dataconnection from './../tools/dataconnection';

class UserSelector extends Component {
    app = null;
    token = "";

    constructor(props) {
        super();
        if(props.app!==undefined) this.app = props.app;
        var users = [];
        this.state = {users: users, reloadData: false, loaded: false, error: ""};
  }

  componentDidMount() {
    this.reloadData();
  }

  componentDidUpdate() {
    this.reloadData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextState.loaded !== this.state.loaded) return true;
    if(nextState.error !== this.state.error) return true;
    return false;
  }

  reloadData() {
    if(this.state.reloadData !== true && this.state.loaded) return;

    var fdata = new FormData();
    fdata.append("username", "admin");
    fdata.append("password", "1234");
    
   fetch(Dataconnection.getUrl("users"), {
      credentials: 'include'//,
      //headers: new Headers({
      //  'Content-Type': 'application/json'
      //
    //}
    //  )
    })
    .then(response => response.json())
    .then(data => this.setState({users: data, loaded: true, reloadData: false}))
    .catch(error => this.setState({users: {}, error: error, loaded:true, reloadData: false}));
}

handleReloadButtonClick() {
  this.setState({error: "", loaded: false});
}

  render() {
    if(!this.state.loaded) {
      return (
        <div className="User-selector"><img src="animation.gif" alt="Betöltés alatt..." /></div>
      );
    }

    if(this.state.error !== "") {
      return (
      <div className="User-selector"><span className="error-message">Hiba az adatok betöltése során: {this.state.error.toString()}</span><br /><button onClick={this.handleReloadButtonClick.bind(this)}>Újra</button></div>
      );
    }

    return (
      <div className="User-selector">
        {this.renderUsers()}
      </div>
    );
  }

  userButtonClickHandler(user) {
    if(this.app==null) return;
    if(this.app.breadCrumb != null) {
        this.app.breadCrumb.setStep(this.app.steps[1]);
    }
    this.app.setState({user: user});
    this.app.forceUpdate();
  }

  renderUsers() {
    var usersToDisplay = this.state.users.map(function(user) {
        return (
            <button key={"userSelectorButton" + user.id + ""} onClick={() => this.userButtonClickHandler(user)}>{user.fullname}</button>
        );
    }.bind(this));

    return usersToDisplay;
  }
}

export default UserSelector;
